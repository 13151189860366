import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","lg":"9","xl":"6"}},[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v("Custom Set Up")]),_c(VCardTitle,{staticClass:"mb-4"},[_vm._v(" Download Your Portal's HTML ")]),_c(VCardSubtitle,[_vm._v(" While we provide the option to download the HTML file for your portal, we "),_c('b',[_vm._v("do not recommend this approach")]),_vm._v(" for several reasons: "),_c('p',{staticClass:"mt-4"},[_c('b',[_vm._v("Manual Updates:")]),_vm._v(" Any changes or updates made to the portal design or functionality will not be automatically applied to your downloaded version. ")]),_c('p',[_c('b',[_vm._v("Technical Complexity:")]),_vm._v(" Hosting and maintaining your own portal requires advanced technical knowledge, such as setting up a server, ensuring security, and managing updates. ")]),_c('p',[_c('b',[_vm._v("Limited Support:")]),_vm._v(" We cannot provide support for portals hosted independently on third-party platforms. ")]),_c('p',[_vm._v(" For the best experience and to receive ongoing support and updates, we strongly encourage using our hosted solution at clientcabin.com. However, if you still prefer to proceed, you can download the HTML by clicking the button below; please make sure to review the setup instructions provided in the README.md file in this export. ")]),_c('p',{staticClass:"primary--text"},[_vm._v(" Support is limited to portals hosted within the white-label domain; we will not be able to assist with issues occurring on external or third-party hosted platforms. ")]),_c(VBtn,{on:{"click":_vm.download}},[_vm._v("Download HTML")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }